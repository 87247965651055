import moment from 'moment-timezone'
import states from '@/models/states.js'

const validateAge = (year, month, day) => {
    return new Promise((resolve, reject) => {
        const dob = moment(year + '-' + month + '-' + day, 'YYYY-MM-DD')
        if (!dob.isValid()) {
            reject('Please enter a valid date.')
            console.log('after reject')
        }
        if (moment().diff(dob, 'year') < 18) {
            reject('Sorry, you need to be 18 to enter this site.')
        }

        resolve(true)
    })
}

const validateDob = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('Please input your date of birth'))
    }
    if (value.length !== 8) {
        return callback(new Error('Please enter a valid date of birth'))
    }
    const dob = moment(value, 'DDMMYYYY')
    if (!dob.isValid()) {
        return callback(new Error('Please enter a valid date of birth'))
    }
    if (moment().diff(dob, 'year') < 18) {
        callback(new Error('You must be 18 years or over'))
    } else {
        callback()
    }
}

const checkState = (rule, value, callback) => {
    if (!states.includes(value)) {
        callback(
            new Error('Sorry, the promotion is not available in your state.')
        )
    } else {
        callback()
    }
}

const rules = {
    title: [
        {
            required: true,
            message: 'Please input your title',
            trigger: 'change'
        }
    ],

    first_name: [
        {
            required: true,
            message: 'Please input your first name',
            trigger: 'blur'
        }
    ],
    last_name: [
        {
            required: true,
            message: 'Please input your last name',
            trigger: 'blur'
        }
    ],
    surname: [
        {
            required: true,
            message: 'Please input your surname',
            trigger: 'blur'
        }
    ],
    phone: [
        {
            required: true,
            message: 'Please input your phone number',
            trigger: 'blur'
        },
        {
            min: 9,
            message: 'Please enter a valid phone number',
            trigger: 'blur'
        }
    ],
    email: [
        {
            type: 'email',
            required: true,
            message: 'Please input your email address',
            trigger: ['blur', 'change']
        }
    ],
    date_of_birth: [
        { validator: validateDob, trigger: ['blur', 'change'] },
        {
            required: true,
            message: 'Please input your date of birth',
            trigger: ['blur', 'change']
        }
    ],
    street: [
        {
            required: true,
            message: 'Please input your address',
            trigger: ['blur', 'change', 'focus']
        }
    ],
    suburb: [
        {
            required: true,
            message: 'Please input your suburb',
            trigger: ['blur', 'change']
        }
    ],
    city: [
        {
            required: true,
            message: 'Please input your city',
            trigger: ['blur', 'change']
        }
    ],
    state: [
        {
            required: true,
            message: 'Please input your state',
            trigger: 'change'
        },
        { validator: checkState, trigger: ['change'] }
    ],
    postcode: [
        {
            required: true,
            message: 'Please input your postcode',
            trigger: ['blur', 'change']
        },
        {
            min: 4,
            max: 4,
            message: 'Please enter a valid postcode',
            trigger: ['blur', 'change']
        }
    ],
    terms: [
        {
            type: 'array',
            required: true,
            message: 'You must agree with terms and condition.',
            trigger: 'blur'
        }
    ],
    retailer: [
        {
            required: true,
            message: 'Please select the retailer you purchased from',
            trigger: ['blur', 'change']
        }
    ]
}

const receiptRules = {
    list: [
        {
            required: true,
            message: 'Please select files to upload.',
            trigger: 'change'
        }
    ]
}
const ageGateRules = {
    day: [
        {
            required: true,
            message: ' ',
            trigger: 'blur'
        },
        {
            min: 2,
            max: 2,
            message: ' ',
            trigger: 'blur'
        },
        {
            pattern: /[0-9]{2}$/g,
            message: ' ',
            trigger: 'blur'
        }
    ],
    month: [
        {
            required: true,
            message: ' ',
            trigger: 'blur'
        },
        {
            min: 2,
            max: 2,
            message: ' ',
            trigger: 'blur'
        },
        {
            pattern: /[0-9]{2}$/g,
            message: ' ',
            trigger: 'blur'
        }
    ],
    year: [
        {
            required: true,
            message: ' ',
            trigger: 'blur'
        },
        {
            min: 4,
            max: 4,
            message: ' ',
            trigger: 'blur'
        },
        {
            pattern: /[0-9]{4}$/g,
            message: ' ',
            trigger: 'blur'
        }
    ]
}

const termsRules = {
    terms: [
        {
            type: 'array',
            required: true,
            message: 'You must agree with terms and condition.',
            trigger: 'blur'
        }
    ]
}

const entryRules = {
    first_name: rules.first_name,
    last_name: rules.surname,
    email: rules.email,
    state: rules.state,
    suburb: rules.suburb,
    street: rules.street,
    date_of_birth: rules.date_of_birth,
    phone: rules.phone,
    title: rules.title,
    postcode: rules.postcode,
    retailer: rules.retailer
}

export { entryRules, ageGateRules, receiptRules, termsRules, validateAge }
