<template>
  <div class="age-gate">
    <el-dialog
      title=""
      :visible.sync="ageGateVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-row justify="center" type="flex">
        <el-col :xs="24">
          <h4 class="text-center">You must be over 18 to visit this website</h4>
          <p class="text-center">Please enter your date of birth:</p>

          <el-form ref="ageGateForm" :model="ageGateForm" :rules="rules" class="age-gate-form">
            <el-row :gutter="10" justify="center" type="flex">
              <el-col :span="6" :sm="5" :md="4">
                <el-form-item required prop="day">
                  <el-input ref="day" v-model="ageGateForm.day" :maxlength="2" placeholder="DD" inputmode="numeric" @keyup.native="onKeyup('month',$event)" />
                </el-form-item>
              </el-col>
              <el-col :span="6" :sm="5" :md="4">
                <el-form-item required prop="month">
                  <el-input ref="month" v-model="ageGateForm.month" :maxlength="2" placeholder="MM" inputmode="numeric" @keyup.native="onKeyup('year',$event)" />
                </el-form-item>
              </el-col>
              <el-col :span="7" :sm="6" :md="5">
                <el-form-item required prop="year">
                  <el-input ref="year" v-model="ageGateForm.year" :maxlength="4" placeholder="YYYY" inputmode="numeric" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-alert v-if="error" class="my-3 pt-2 pb-3" effect="dark" :closable="false" type="error">{{ error }}</el-alert>
                <center><el-button type="primary" class="age-gate-btn" @click="submit">ENTER</el-button></center>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import {mapGetters} from 'vuex'
import { EventBus } from '@/utils/event-bus'
import {ageGateRules, validateAge} from '@/utils/validators'
// import { TheMask } from 'vue-the-mask'

export default {
    name: 'AgeGate',
    components: {
    // TheMask
    },
    data() {
        return {
            error: null,
            ageGateVisible: true,
            ageGateForm: {
                day: '',
                month: '',
                year: ''
            },
            rules: ageGateRules
        }
    },
    computed: {
        ...mapGetters(['ageGate','dob'])
    },
    created() {
        this.ageGateVisible = !this.ageGate
    },
    methods: {
        onKeyup(nextName, event) {
            if (event.target.value.length === 2 && event.keyCode >= 48 && event.keyCode <= 57) {
                this.$refs[nextName].focus()
            }
        },
      
        submit() {
            this.$refs['ageGateForm'].validate((valid) => {
                if (valid) {
                    const {year, month, day} = this.ageGateForm
                    validateAge(year, month, day).then(() => {
                        const dob = moment(day.toString() + month.toString() + year.toString(), 'DDMMYYYY').format('DDMMYYYY')
                        this.$store.dispatch('setAgeGate',true)
                        this.ageGateVisible = false
                        this.$store.dispatch('setDob', dob)
                        EventBus.$emit('updateDOB', dob)
                    }).catch(error=>{
                        this.error = error
                    })
                   
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped></style>
