<template>
  <div class="d-flex flex-column h-100">   
    <app-header />
    <main id="main" role="main" class="flex-shrink-0 py-5">
      <div class="container-fluid">

        <div class="row d-flex justify-content-center">
          <div class="col-12 text-center">
            <slot name="headerText">
              <h4 class="text-center"> {{ promotion.title }} </h4>
            </slot>
            <slot name="headerSubtext">
              <p class="text-center">{{ promotion.description }}</p>
            </slot>
          </div>
          <div class="col-12">
            <slot />

          </div>
          <slot name="footerSubtext" />
        </div>

      </div>
    </main>
    <app-footer />
  </div>
</template>

<script>
import AppHeader from './components/Header'
import AppFooter from './components/Footer'
import { activityMixin } from '@/mixins/activity'

export default {
    name:'AppDefaultWide',
    components: {
        AppHeader,
        AppFooter
    },
    mixins:[activityMixin],
   
    data() {
        return {
            promotion: {
                title: 'Win the Ultimate Grey Goose Entertainment Pack',
                description: ''
            }
        }
    }

}
</script>

<style></style>
