<template>
  <header>
    <div class="scroll" style="position:relative">
      <div >
        <router-link :to="{ path: '/', query:routerQuery }"><img :src="banner" class="img-header"></router-link>
      </div>
      <a class="arrow" href="#main" @click.prevent="scrollTo"><span /></a>
    </div>
  </header>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name:'AppHeader',
    computed: {
        ...mapGetters(['activity','routerQuery']),
        showScroll() {
            return this.$route.meta.showScroll
        },
        banner(){
            return this.activity.activity_img
        }
    },
    
    methods: {
        scrollTo() {
            window.scrollTo({
                top: document.getElementById('main').offsetTop,
                behavior: 'smooth'
            })
        }
    }

}
</script>

<style lang="scss">
.scene-container{
  position: absolute;
  bottom: 8%;
  max-width: 80%;

  img.layer{
    max-width: 100%;

  }
  .layer-bottle{
    bottom: 40px;
  }
}
.logo-container{
  padding: 10px 10px 0 10px;
}
.img-logo{
  max-width: 60px;
  display: block;

}
</style>
