const MAIL_ENDPOINT =
    'https://mclpxoz6wi.execute-api.ap-southeast-2.amazonaws.com/production/send'
const FIREBASE_API_KEY = 'AIzaSyBse4WGj7AFxjks1PrfJrC9-wNAqTKT4g4'
const FIREBASE_AUTH_DOMAIN = 'miiapp-australia.firebaseapp.com'
const FIREBASE_DATABASE_URL = 'https://miiapp-australia.firebaseio.com'
const FIREBASE_PROJECT_ID = 'miiapp-australia'
const FIREBASE_STORAGE_BUCKET = 'miiapp-australia.appspot.com'
const FIREBASE_MESSAGING_SENDER_ID = '808623130863'
const FIREBASE_MESSAGING_APP_ID = '1:808623130863:web:4e2ac8ff6e623455db731a'
const FIREBASE_MEASUREMENT_ID = 'G-YPKMH6V11X'


const MICROSERVICE_ENDPOINT =
    'https://vyioc3llwk.execute-api.ap-southeast-2.amazonaws.com/prod' //get bsbs

/**
 * TO BE UPDATED FOR EACH PROMOTION
 */
const ADMIN_EMAIL = 'support@pinkzebra.com.au' // admin email should be listed in lambda function

const ACTIVITY_NAME = 'Dive into the Deli Woolworths Promotion 2022' //Page title prefix, see router/index.js
const MYY_ACTIVITY_CODE = 'peps0021' // activity code in myy
const MYY_SOURCE_IDENTIFIER = 'peps0021' //user source identifier in myy
const MYY_OPTIN = 'PepsiCo' // opt in value
const RECEIPT_UPLOAD_FOLDER = 'web_peps0021_receipts' // receipt uploaded folder in s3

const PRIVACY_POLICY_URL = 'https://www.pepsico.com/legal/privacy' // policy link
const TERMS_OF_USE_URL = 'https://www.pepsico.com.au/terms-of-use' // terms of use 
const RED_ROCK_DELI_URL = 'https://redrockdeli.com.au/' // terms of use 

/** END */

const ACTIVITY_CONTACT_SUBJECT = 'Promotion Enquiry - '+ ACTIVITY_NAME


export {
    MAIL_ENDPOINT,
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_DATABASE_URL,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_MESSAGING_APP_ID,
    FIREBASE_MEASUREMENT_ID,
    RECEIPT_UPLOAD_FOLDER,
    ACTIVITY_NAME,
    MYY_ACTIVITY_CODE,
    MYY_SOURCE_IDENTIFIER,
    MYY_OPTIN,
    ADMIN_EMAIL,
    ACTIVITY_CONTACT_SUBJECT,
    MICROSERVICE_ENDPOINT,
    PRIVACY_POLICY_URL,
    TERMS_OF_USE_URL,
    RED_ROCK_DELI_URL
}
