import { service } from '@/utils/request'

export function participate(data) {
    return service({
        url: '/activity/participate',
        method: 'post',
        data
    })
}

export function getActivitiesByCode(code) {
    return service({
        url: '/activities',
        method: 'get',
        params: {
            site_code: code
        }
    })
}
