<template>
  <div v-loading="!activity._id" class="d-flex flex-column h-100" :version="version">
    <app-header />
    <main id="main" role="main" class="flex-shrink-0 py-5" name="main">
      <div class="container-fluid">
        <div class="row d-flex justify-content-center">
          <div class="col-11 col-md-10 col-lg-9  col-xl-8 text-center">
            <slot name="headerText">
              <h4 class="text-center">{{ promotion.title }}</h4>
            </slot>
            <slot name="headerSubtext">
              <p class="text-center">{{ promotion.description }}</p>
            </slot>
          </div>
          <div class="col-11 col-md-10 col-lg-9 col-xl-8">
            <slot />
          </div>
          <div class="col-11 col-md-10 col-lg-8 footerSubtext text-center">
            <slot name="footerSubtext" />
          </div>
        </div>
      </div>
    </main>
    <app-footer />
  </div>
</template>

<script>
import AppHeader from './components/Header'
import AppFooter from './components/Footer'
import {MYY_ACTIVITY_CODE} from '@/settings'
import { activityMixin } from '@/mixins/activity'

export default {
    name:'AppDefault',
    components: {
        AppHeader,
        AppFooter
    },
    mixins:[activityMixin],
   
    data() {
        return {
            promotion: {
                title: '',
                description: ''
            },
            version: MYY_ACTIVITY_CODE
        }
    }
}
</script>

<style>
.footerSubtext p{
  font-size: 0.8rem !important;
}
</style>
