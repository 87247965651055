import Vue from 'vue'
import moment from 'moment'
import VueRouter from 'vue-router'
import { ACTIVITY_NAME } from '@/settings.js'

Vue.use(VueRouter)

import '@/styles/index.scss'
const metaTags = [
    {
        name: 'og:image',
        content: window.location.origin + '/social.jpg'
    }
]

const routes = [
    {
        path: '/',
        component: () => import('@/views/entry'),
        name: 'Enter',
        meta: {
            title: ACTIVITY_NAME,
            metaTags: metaTags,

            showScroll: true
        },
        beforeEnter: (to, from, next) => checkActivityDates(to, from, next)
    },
    {
        path: '/terms',
        component: () => import('@/views/terms'),
        name: 'Terms',

        meta: {
            title: ACTIVITY_NAME + ' - Terms and Conditions',
            metaTags: metaTags
        }
    },
    {
        path: '/result',
        component: () => import('@/views/result'),
        name: 'Result',
        meta: {
            title: ACTIVITY_NAME + ' - Success',
            metaTags: metaTags
        },
        beforeEnter: checkActivityDates
    },
    {
        path: '/iframe-test',
        component: () => import('@/views/iFrameTest'),
        name: 'iFrame Test',
        meta: {
            title: ACTIVITY_NAME + ' - iFrame Test',
            metaTags: metaTags
        }
    },
    {
        path: '/contact',
        component: () => import('@/views/contact'),
        name: 'Contact Us',
        meta: {
            title: ACTIVITY_NAME + ' -  Contact Us',
            metaTags: metaTags
        }
    },
    {
        path: '/landing',
        component: () => import('@/views/holdings/landing'),
        name: 'Landing',

        meta: {
            title: ACTIVITY_NAME + ' -  Landing',
            metaTags: metaTags
        }
    },
    {
        path: '/end',
        component: () => import('@/views/holdings/end'),
        name: 'Promotion has Ended',

        meta: {
            title: ACTIVITY_NAME + ' -  Promotion has ended',
            metaTags: metaTags
        }
    },
    {
        path: '/winners',
        component: () => import('@/views/holdings/winners'),
        name: 'Winners',

        meta: {
            title: ACTIVITY_NAME + ' -  Winners',
            metaTags: metaTags
        }
    }
]
const inPromoDates = () => {
    const current = moment()
    const activityStart = moment(
        process.env.VUE_APP_PROMO_START,
        'YYYY-MM-DD HH:mm:ss ZZ'
    )
    const activityEnd = moment(
        process.env.VUE_APP_PROMO_END,
        'YYYY-MM-DD HH:mm:ss ZZ'
    )
    if (current.isBefore(activityStart)) {
        return -1
    } else if (current.isAfter(activityEnd)) {
        return 1
    } else return 0
}

const inPromotionDates = inPromoDates()
const checkActivityDates = (to, from, next) => {
    if (inPromotionDates < 0) {
        next('/landing')
    } else if (inPromotionDates > 0) {
        next('/winners')
    } else {
        next()
    }
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find(r => r.meta && r.meta.title)

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find(r => r.meta && r.meta.metaTags)

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(
        document.querySelectorAll('[data-vue-router-controlled]')
    ).map(el => el.parentNode.removeChild(el))

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next()

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
        .map(tagDef => {
            const tag = document.createElement('meta')

            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key])
            })

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '')

            return tag
        })
        .forEach(tag => document.head.appendChild(tag))

    next()
})

export default router
