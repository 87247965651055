import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        firebaseUid: null,
        firebaseToken: null,
        receiptData: {
            receiptId: null,
            receiptUrl: null,
            venue: null,
            name: null,
            orginalName: null
        },
        user: {
            _id: null
        },
        winner: {},
        activity: {},
        ageGate: false,
        dob: '',
        retailer: null
    },
    getters: {
        getFirebaseToken: state => {
            return state.firebaseToken
        },
        getFirebaseUid: state => {
            return state.firebaseUid
        },
        getUserId: state => {
            return state.user._id
        },
        getReceiptId: state => {
            return state.receiptData.receiptId
        },
        getReceipt: state => {
            return state.receiptData
        },
        winner: state => {
            return state.winner
        },
        activity: state => {
            return state.activity
        },
        ageGate: state => {
            return state.ageGate || state.retailer
        },
        routerQuery: state => {
            const query = { agegate: state.ageGate }
            if (state.retailer) {
                query.r = state.retailer
            }
            return query
        },
        retailer: state => {
            return state.retailer
        },
        dob: state => {
            return state.dob
        }
    },
    mutations: {
        setFirebaseToken(state, token) {
            state.firebaseToken = token
        },
        setFirebaseUid(state, firebaseUid) {
            state.firebaseUid = firebaseUid
        },
        setReceiptData(state, data) {
            state.receiptData = Object.assign({}, data)
        },
        updateUser(state, user) {
            state.user = Object.assign(state.user, user)
        },
        setWinnerData(state, data) {
            state.winner = Object.assign({}, data)
        },
        setActivity(state, data) {
            state.activity = Object.assign({}, data)
        },
        setDob(state, data) {
            state.dob = data
        },
        setAgeGate(state, data) {
            state.ageGate = data
        },
        setRetailer(state, data) {
            state.retailer = data
        }
    },
    actions: {
        setFirebaseToken({ commit }, token) {
            commit('setFirebaseToken', token)
        },
        setFirebaseUid({ commit }, uid) {
            commit('setFirebaseUid', uid)
        },
        setReceiptData({ commit }, data) {
            commit('setReceiptData', data)
        },
        updateUser({ commit }, user) {
            commit('updateUser', user)
        },
        setWinnerData({ commit }, winner) {
            commit('setWinnerData', winner)
        },
        setActivity({ commit }, activity) {
            commit('setActivity', activity)
        },
        setDob({ commit }, data) {
            commit('setDob', data)
        },
        setAgeGate({ commit }, data) {
            commit('setAgeGate', data)
        },
        setRetailer({ commit }, data) {
            commit('setRetailer', data)
        },
        reset({ commit }) {
            commit('setDob', null)
            // commit('setAgeGate', null)
            commit('setFirebaseToken', null)
            commit('setFirebaseUid', null)
        }
    },
    modules: {}
})
