<template>
  <footer class="footer mt-auto">
    <el-row class="bg-black">
      <el-col>
        <div class="container-fluid">
          <a target="_blank" :href="redrockdeli"><img src="@/assets/logo.png" class="m-3" /></a>
          <ul class="footer-links">
            <li>
               <router-link :to="{ path: '/', query:routerQuery }">Enter</router-link>
            </li>
            <li>
              <router-link :to="{ path: 'terms', query:routerQuery  }">Promotional Terms &amp; Conditions</router-link>
            </li>
            <li>
              <a target="_blank" :href="termsofuse">PepsiCo Terms of Use</a>
            </li>
            <li>
              <a target="_blank" :href="policy">PepsiCo Privacy Policy</a>
            </li>
            <li>
              <router-link :to="{ path: 'contact', query:routerQuery }">Contact Us</router-link>
            </li>
            <li>© PepsiCo 2022</li>           
          </ul>
        </div>
      </el-col>
    </el-row>
  </footer>
</template>

<script>
import {mapGetters} from 'vuex'
import {PRIVACY_POLICY_URL, TERMS_OF_USE_URL, RED_ROCK_DELI_URL} from '@/settings'
export default {
    name:'AppFooter',
    data() {
        return {
            policy: PRIVACY_POLICY_URL,
            termsofuse: TERMS_OF_USE_URL,
            redrockdeli: RED_ROCK_DELI_URL
        }
    },
    computed: {
        ...mapGetters(['routerQuery'])
    },
    created() {
     
    }
}
</script>

<style >
.grecaptcha-badge{
  display: none;
}
</style>
