import axios from 'axios'

import { getFirebaseToken } from './firebase'

import hmacSHA256 from 'crypto-js/hmac-sha256'
import store from '@/store/index.js'

const getRandom = length => {
    const result = []
    const characters =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result.push(
            characters.charAt(Math.floor(Math.random() * charactersLength))
        )
    }
    return result.join('')
}

// import { getFirebaseUid } from './firebase'
const options = {
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5 * 60 * 1000,
    withCredentials: true,
    validateStatus: function(status) {
        return status >= 200 && status < 500 && status !== 404 // default
    }
}
const interceptorsConfig = async config => {
    const token = await getFirebaseToken()
    config.headers['x-firebase-id-token'] = token

    const userId = store.getters.getFirebaseUid

    if (userId) {
        const timestamp = Date.now()

        const nonce = userId + timestamp + getRandom(8)
        const sign = hmacSHA256(nonce, 'hVmYq3t6w9z$C&F)').toString()

        config.headers['timestamp'] = timestamp
        config.headers['nonce'] = nonce
        config.headers['signature'] = sign
    }

    return config
}

const interceptorsResponse = response => {
    if (response.status >= 400) {
        return Promise.reject(response.data || response)
    }
    return response.data
}
const service = axios.create(options)

service.interceptors.request.use(interceptorsConfig, error => {
    Promise.reject(error.response.data || error.message)
})

service.interceptors.response.use(interceptorsResponse, error => {
    console.log(error)
    return Promise.reject(error.response.data || error.message)
})

const servicePlain = axios.create(options)

servicePlain.interceptors.request.use(
    config => config,
    error => {
        Promise.reject(error.response.data || error.message)
    }
)

servicePlain.interceptors.response.use(interceptorsResponse, error => {
    return Promise.reject(
        error?.response?.data || error?.message || 'Server Error'
    )
})
export { service, servicePlain }
