<template>
  <div class="d-flex flex-column" :version="version">
    <age-gate />
    <main id="main" role="main" class="w-100 h-100" name="main" style="overflow-x: hidden;">
<!--      <div class="container-fluid">-->
<!--        <div class="row d-flex justify-content-center">-->
<!--          <div class="col-12 col-md-10 col-lg-9 col-xl-8">-->
<!--            <slot name="headerText">-->
<!--              <h4>{{ promotion.title }}</h4>-->
<!--            </slot>-->
<!--            <slot name="headerSubtext">-->
<!--              <p class="text-center">{{ promotion.description }}</p>-->
<!--            </slot>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <slot />
<!--      <div class="container-fluid">-->
<!--        <div class="row d-flex justify-content-center">-->
<!--          <div class="col-12 col-md-10 col-lg-8 footerSubtext text-center">-->
<!--            <slot name="footerSubtext" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="container-fluid">-->
<!--        <div class="row d-flex justify-content-center">-->
<!--          <div class="col-12 col-md-10 col-lg-8 footerSubtext text-center">-->
<!--            <slot name="footerSubtext" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </main>
<!--    <app-footer />-->
  </div>
</template>

<script>
// import AppFooter from './components/Footer'
import AgeGate from '@/components/Entry/AgeGate'
import {MYY_ACTIVITY_CODE} from '@/settings'

export default {
    name:'ResultsLayout',
    components: {
        // AppFooter,
        AgeGate
    },
    created () {
        this.$store.dispatch('setRetailer', !!this.$route.query.r)
    },
    data() {
        return {
            promotion: {
                title: '',
                description: ''
            },
            version: MYY_ACTIVITY_CODE
        }
    }
}
</script>

<style>
.footerSubtext p{
  font-size: 0.8rem !important;
}
</style>
