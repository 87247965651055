import * as firebase from 'firebase/app'
// import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import store from '../store'
import {
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_DATABASE_URL,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_MESSAGING_APP_ID,
    FIREBASE_MEASUREMENT_ID
} from '@/settings.js'
var firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    databaseURL: FIREBASE_DATABASE_URL,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_MESSAGING_APP_ID,
    measurementId: FIREBASE_MEASUREMENT_ID
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const getFirebaseUid = async () => {
    try {
        let firebaseUid = store.getters.getFirebaseUid
        if (firebaseUid) return firebaseUid
        else {
            const response = await firebase.auth().signInAnonymously()

            if (response.user) {
                store.dispatch('setFirebaseUid', response.user.uid)
                return response.user.uid
            } else {
                throw new Error(response)
            }
        }
    } catch (error) {
        this.$message({
            type: 'error',
            message: error.data || 'Error'
        })
    }
}

const getFirebaseToken = async () => {
    const token = store.getters.getFirebaseToken
    if (token) {
        return token
    } else {
        await getFirebaseUid()
        const idToken = await firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)

        store.dispatch('setFirebaseToken', idToken)

        return idToken
    }
}

export { getFirebaseUid, getFirebaseToken }
