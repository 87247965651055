import Vue from 'vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import VueKinesis from 'vue-kinesis'

import 'element-ui/lib/theme-chalk/index.css'
import '@fortawesome/fontawesome-free/css/all.css'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import AppMain from '@/views/layout/Default'
import AppMainWide from '@/views/layout/DefaultWide'
import ResultsLayout from '@/views/layout/Results'


/* eslint-enable */
Vue.config.productionTip = false
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(ElementUI, { locale })

Vue.use(VueKinesis)

Vue.component('AppMain', AppMain)
Vue.component('AppMainWide', AppMainWide)
Vue.component('ResultsLayout', ResultsLayout)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
